@import "./theme.scss";
@import './font.scss';

html{
    scroll-behavior: initial;
    overflow: hidden;
}

html, body{
    width: 100%;
    min-height: 100%;
    word-wrap: break-word;

}

.headerSpace{
    height: 120px;
}