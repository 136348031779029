@import "./common.scss";

.homepage{
    cursor: default;
    #section_1{
        position: relative;
        width: 100vw;
        min-height: calc(100vh - 120px);
        height: 100vh;
    }
    .mainpage{
        // margin:40px;
        // display: flex;
        position: relative;
        top:20vh;
        left:0;
        justify-content: space-between;
        width: 100vw;
        // min-height: calc(100vh - 120px);
        height: calc(100vh - 120px)
    }
    .pagecenter{
        z-index: 99;
        margin:auto;
        max-width: 1200px;
        width: auto;
        display: flex;
        // position: sticky;
        justify-content: space-between;
        height: calc(100vh - 120px)
    }
    .frame{
        width: 300px;
        height: 300px;
        border-radius: 150px 150px 0 0%;
        padding: 0 0 150px;
        background-color: #cdcdd9;
        transform-style: preserve-3d;
        position: relative;
    }
    .imgContainer{
      width: 500px;
      // height: 900px;
      overflow: hidden;
      position: absolute;
      bottom: -10px;
      left: -20px;
    }

    .song1{
        height: 650px;
        // left: -20px;
    }
    .text{
        margin-top: 60px;
        padding:20px;
        // height: 100vh;
        // background-color: red;
    }
    .flowText span {
        cursor: pointer;
        display: inline-block;
        font-size: 100px;
        user-select: none;
        line-height: .8;
    }
      
    .flowText span:nth-child(1).active {
    animation: balance 1.5s ease-out;
    transform-origin: bottom left;
    }
    @keyframes balance {
        0%, 100% {
          transform: rotate(0deg);
        }
        
        30%, 60% {
          transform: rotate(-45deg);
        }
      }

      
      
      .flowText span:nth-child(2).active {
        animation: shrinkjump 1s ease-in-out;
        transform-origin: bottom center;
      }
      
      @keyframes shrinkjump {
        10%, 35% {
          transform: scale(2, .2) translate(0, 0);
        }
        
        45%, 50% {
          transform: scale(1) translate(0, -150px);
        }
        
        80% {
          transform: scale(1) translate(0, 0);
        }
      }
      
      .flowText span:nth-child(6).active {
        animation: falling 2s ease-out;
        transform-origin: bottom center;
      }
      
      @keyframes falling {
        12% {
          transform: rotateX(240deg);
        }
        
        24% {
          transform: rotateX(150deg);
        }
        
        36% {
          transform: rotateX(200deg);
        }
        
        48% {
          transform: rotateX(175deg);
        }
        
        60%, 85% {
          transform: rotateX(180deg);
        }
        
        100% {
          transform: rotateX(0deg);
        }
      }
      
      .flowText span:nth-child(4).active {
        animation: rotate 1s ease-out;
      }
      
      @keyframes rotate {
        20%, 80% {
          transform: rotateY(180deg);
        }
        
        100% {
          transform: rotateY(360deg);
        }
      }
      
      .flowText span:nth-child(5).active {
        animation: toplong 1.5s linear;
      }
      
      @keyframes toplong {
        10%, 40% {
          transform: translateY(-18vh) scaleY(1);
        }
        
        90% {
          transform: translateY(-18vh) scaleY(4);
        }
      }

      .flowText span:nth-child(7).active {
        animation: balance 1.5s ease-out;
        transform-origin: bottom left;
        }
        @keyframes balance {
            0%, 100% {
              transform: rotate(0deg);
            }
            
            30%, 60% {
              transform: rotate(-45deg);
            }
          }
    

}
.page{
    min-width: 100vw;
    min-height: 100vh;
    background-color: $main-background;
}

.section1_pic{
    // position: absolute;
    position: relative;
    // top:0;
    // left: 0;
    width: 100vw;
    min-height: calc(100vh - 120px);
}
.s1_1{
    width: 700px;
    transform: rotate(190deg);
    position: absolute;
    left:30vw;
    top:-20vh
    // rotate: 180;
}

.s1_2{
    width: 400px;
    transform: rotate(-100deg);
    position: absolute;
    bottom:0;
    left:30vw;
    // top:-10vh
    // rotate: 180;
}
.s1_3{
    width: 400px;
    transform: rotate(-20deg);
    position: absolute;
    bottom:-20vh;
    right:-0
    // rotate: 180;
}

.bigTitle{
  line-height: 0 !important;
}

@media only screen and (max-width: 1080px) {
  .homepage{
    .frame{
        width: 200px;
        height: 200px;
        border-radius: 100px 100px 0 0%;
        padding: 0 0 100px;
        background-color: #cdcdd9;
        transform-style: preserve-3d;
        position: relative;
        display: none;
    }
    .imgContainer{
      width: 400px;
      // height: 900px;
      overflow: hidden;
      position: absolute;
      bottom: -10px;
      left: -20px;
    }
    .song1{
      height: 550px;
      // left: -20px;
    }
  }
}