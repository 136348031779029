.header{
    width: calc(100vw - 40px);
    position: fixed;
    margin:20px;
    display: flex;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    z-index: 999;
    h2{
        cursor: pointer;
    }
}