@import './common.scss';

#section_5{
    position: relative;
    width: 100vw;
    min-height: calc(100vh - 120px);
    cursor: default;
    // height: 500vh;
    .mainpage5{
        // position: fixed;
        position: relative;
        text-align: center;
        top:120px;
        left:0;
        width: 100vw;
        background-color: $main-background;
        // min-height: calc(100vh - 120px);
        .pagecenter5{
            z-index: 99;
            margin:220px auto;
            max-width: 1200px;
            display: block;
            width: auto;
        }
        .blocks{
            display: flex;
        }
        .coreSkillBlock {
            gap:20px;
            width: 30vw;
        }
        .coreSkillBlock:not(:last-child) {
            margin-right: 50px;
        }    
    }
    h1{
        text-align: center;
        margin:50px 0;
    }
    .icon{
        vertical-align: middle;
    }
    h3{
        margin:0;
        vertical-align: middle;
    }
    .touchblock{
        display: inline-flex;
        gap:20px;
        text-align: center;
        cursor: pointer !important;
        height: 40px;
        line-height: 40px;
    }
    .touchblocks{
        margin:auto;
        text-align: center;
    }

    // .backtotop{
    //     text-align: center;
    //     // cursor: pointer !important;
    // }

    .backtotop{
        color:#CCCCCC;
        display: inline-block;
        text-align: center;
        cursor: pointer !important;
    }
    .backtotop:hover{
        color:$font-color;
        border-bottom:1px solid #CCCCCC
    }

    .touchButton{
        margin-top: 60px;
    }
}