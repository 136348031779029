@import './common.scss';

#section_3{
    position: relative;
    width: 100vw;
    min-height: calc(100vh - 120px);
    cursor: default;
    // height: 100vh;
    .mainpage3{
        display: flex;
        position: relative;
        // position: fixed;
        top:120px;
        left:0;
        width: 100vw;
        min-height: calc(100vh - 120px);
        .pagecenter3{
            z-index: 99;
            // margin:5vh auto;
            width: auto;
            max-width: 1200px;
            display: block;
        }
        .blocks{
            display: flex;
            padding:0 10vw ;
        }
        .coreSkillBlock {
            gap:20px;
            width: 30vw;
        
        }
        .coreSkillBlock:not(:last-child) {
            margin-right: 50px;
            
        }    }
    h1{
        text-align: center;
        // margin:50px auto;
        width:100vw;
    }
    .othersideImg{
        width: 30vw;
        border: 2px solid $secondary-color;
        border-radius: 50px;
        height: 40vw;
        object-fit:cover;
        margin-top: 50px;
    }
}