@import './common.scss';

#section_2{
    position: relative;
    width: 100vw;
    min-height: calc(100vh - 120px);
    cursor: default;
    .mainpage2{
        display: flex;
        top:120px;
        left:0;
        width: 100vw;
        min-height: calc(100vh - 120px);
        .pagecenter2{
            z-index: 99;
            width: auto;
            margin:80px auto;
            max-width: 1200px;
            display: block;
        }
        .blocks{
            display: grid;
            grid-template-columns:1fr 1fr 1fr;
            gap: 40px;
            .block{
                text-align: center;
                padding: 40px;
                .highlightTitle{
                    margin:20px;
                    @extend h2
                }
            }
            .block:not(:last-child){
                border-right:solid 1px $secondary-color;
            }
        }
    }
    h1{
        text-align: center;
        margin:50px 0;
    }
}

@media only screen and (max-width: 1080px) {
    #section_2 .mainpage2 .blocks{
            display: block;
            // grid-template-columns:1fr 1fr 1fr;
            // gap: 40px;
        }
  }