@import './theme.scss';

@font-face{
    font-family: 'audioWide'; // --自定义名字就好
    src: url("../font/Audiowide-Regular.ttf"); // --下载的字体文件路径
}

.websiteName{
    font-family: 'audioWide';
}
h1{
    font-size: 80px;
    font-weight: 1000;
    color:$font-color
}

h2{
    font-size: 40px;
    font-weight: 700;
    color:$font-color
}

h3{
    font-size: 30px;
    font-weight: 700;
    color:$font-color
}

h4{
    font-size: 50px;
    font-weight: 900;
    color:$font-color
}

@media only screen and (max-width: 1080px) {
    h1{
        font-size: 50px;
        font-weight: 1000;
        color:$font-color
    }
    
    h2{
        font-size: 30px;
        font-weight: 700;
        color:$font-color
    }
    
    h3{
        font-size: 20px;
        font-weight: 700;
        color:$font-color
    }
    
    h4{
        font-size: 40px;
        font-weight: 900;
        color:$font-color
    }
  }