@import './common.scss';

#section_4{
    position: relative;
    width: 100vw;
    min-height: calc(100vh - 120px);
    height: 100vh;
    cursor: default;
    .mainpage4{
        display: flex;
        // position: fixed;
        position: relative;
        top:40px;
        left:0;
        width: 100vw;
        min-height: calc(100vh - 120px);
        .pagecenter4{
            z-index: 99;
            margin:40px auto;
            max-width: 1200px;
            display: block;
            width: auto;
        }
        .corepartblocks{
            grid-template-columns:1fr 1fr 1fr;
            .corepartblock{
                text-align: center;
                padding: 20px;
                .highlightTitle{
                    margin-bottom:20px;
                    @extend h2
                }
            }
            // .corepartblock:hover{
            //     background-color:  $secondary-color !important;
            //     color:white !important;
            //     .highlightTitle{
            //         color:white;
            //     }
            // }
            .corepartblock:not(:last-child){
                border-bottom:solid 1px $secondary-color;
            }
            .corepartblock:first-child{
                padding-top: 0;
            }

        }
    }
    h1{
        text-align: center;
        margin-bottom: 30px;
    }
}